import { ApolloClient, HttpLink, InMemoryCache } from '@apollo/client'
import fetch from 'node-fetch'

/*
 * This is the configuration for `gatsby-plugin-apollo` using ApolloClient instance
 * For information, see the docs:
 * - gatsby-plugin-apollo: https://www.gatsbyjs.com/plugins/gatsby-plugin-apollo/
 * - Apollo Client v3: https://www.apollographql.com/docs/react/api/core/ApolloClient
*/
const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: new HttpLink({
    uri: process.env.GATSBY_GRAPHQL_SERVER_API,
    // You must provide a fetch implementation as an option to HttpLink
    fetch
  })
})

// Your client instance must be the default export
export default client