import React, { createContext, useReducer } from "react"
import { isIE } from "react-device-detect"

const initialState = {
  refinance: true,
  purpose: "OO",
  interestType: "ALL",
  interestPaymentType: "ALL",
  loanAmount: 0,
  propertyValue: 0,
  loanTerm: 30,
  interestRate: 0,
  ongoingFee: 0,
  feeFrequency: "Annual",
  showProducts: false,
  showFilter: true,
  compareLoans: [],
  showLoanComparisonModal: false,
  showAffiliateLeadFormModal: false,
  showBrowserMessageModal: isIE,
  affiliateLeadFormState: "PENDING",
  selectedProduct: null,
  expanded: []
}
const GlobalContext = createContext(initialState)
const { Provider } = GlobalContext

const StateProvider = ({ children }) => {
  const [state, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case "SET_REFINANCE":
        return Object.assign({}, state, {
          refinance: action.refinance,
        })
      case "SET_PURPOSE":
        return Object.assign({}, state, {
          purpose: action.purpose,
        })
      case "SET_INTEREST_TYPE":
        return Object.assign({}, state, {
          interestType: action.interestType,
        })
      case "SET_INTEREST_PAYMENT_TYPE":
        return Object.assign({}, state, {
          interestPaymentType: action.interestPaymentType,
        })
      case "SET_LOAN_AMOUNT":
        return Object.assign({}, state, {
          loanAmount: action.loanAmount,
        })
      case "SET_PROPERTY_VALUE":
        return Object.assign({}, state, {
          propertyValue: action.propertyValue,
        })
      case "SET_LOAN_TERM":
        return Object.assign({}, state, {
          loanTerm: action.loanTerm,
        })
      case "SET_INTEREST_RATE":
        return Object.assign({}, state, {
          interestRate: action.interestRate,
        })
      case "SET_ONGOING_FEE":
        return Object.assign({}, state, {
          ongoingFee: action.ongoingFee,
        })
      case "SET_FEE_FREQUENCY":
        return Object.assign({}, state, {
          feeFrequency: action.feeFrequency,
        })
      case "SHOW_PRODUCTS":
        return Object.assign({}, state, {
          showProducts: !state.showProducts,
        })
      case "TOGGLE_FILTER":
        return Object.assign({}, state, {
          showFilter: !state.showFilter,
        })
      case "CLEAR_LOAN_COMPARISON":
        return Object.assign({}, state, {
          compareLoans: [],
        })
      case "ADD_LOAN_COMPARISON":
        const newCompareLoans = state.compareLoans.concat(action.product);
        return Object.assign({}, state, {
          compareLoans: newCompareLoans,
        })
      case "REMOVE_LOAN_COMPARISON":
        return Object.assign({}, state, {
          compareLoans: state.compareLoans.filter(({ ID }) => ID !== action.product.ID)
        })
      case "TOGGLE_LOAN_COMPARISON_MODAL":
        return Object.assign({}, state, {
          showLoanComparisonModal: action.show,
        })
      case "TOGGLE_AFFILIATE_LEAD_FORM_MODAL":
        return Object.assign({}, state, {
          showAffiliateLeadFormModal: action.show,
          selectedProduct: action.product,
          affiliateLeadFormState: "PENDING",
        })
      case "EXPAND_ROW":
        const newExpanded = [...state.expanded, action.rowId]
        return Object.assign({}, state, {
          expanded: newExpanded,
        })
      case "COLLAPSE_ROW":
        return Object.assign({}, state, {
          expanded: state.expanded.filter(x => x !== action.rowId),
        })
      case "SET_AFFILIATE_LEAD_FORM_STATE":
        return Object.assign({}, state, {
          affiliateLeadFormState: action.formState,
        })
      case "TOGGLE_BROWSER_MESSAGE_MODAL":
        console.log("toggle modal")
        return Object.assign({}, state, {
          showBrowserMessageModal: action.show,
        })
      default:
        return state
    }
  }, initialState)

  return <Provider value={{ state, dispatch }}>{children}</Provider>
}

export { GlobalContext, StateProvider }
